<template>
  <a-spin :tip="loadingTip" :spinning="isloading">
    <div class="pxkeji-right-box">
      <a-tabs default-active-key="trainSetting">
        <a-tab-pane key="trainSetting" tab="角色设置">
          <a-form-model
            ref="trainSettingForm"
            :model="info"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-form-model-item
              label="个人用户角色"
              prop="trainSetting.studentRoleId"
            >
              <a-select
                placeholder="请选择"
                v-model="info.trainSetting.studentRoleId"
              >
                <a-select-option
                  :value="info.id"
                  v-for="(info, index) in roleList"
                  :key="index"
                  >{{ info.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              label="教师用户角色"
              prop="trainSetting.teacherRoleId"
            >
              <a-select
                placeholder="请选择"
                v-model="info.trainSetting.teacherRoleId"
              >
                <a-select-option
                  :value="info.id"
                  v-for="(info, index) in roleList"
                  :key="index"
                  >{{ info.name }}</a-select-option
                >
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              label="企业用户角色"
              prop="trainSetting.enterpriseRoleId"
            >
              <a-select
                placeholder="请选择"
                v-model="info.trainSetting.enterpriseRoleId"
              >
                <a-select-option
                  :value="info.id"
                  v-for="(info, index) in roleList"
                  :key="index"
                  >{{ info.name }}</a-select-option
                >
              </a-select>
            </a-form-model-item>
            <a-form-model-item :wrapper-col="{ span: 10, offset: 4 }">
              <pxkeji-btn
                name="保存"
                type="primary"
                @click="trainSettingSubmit"
              />
            </a-form-model-item>
          </a-form-model>
        </a-tab-pane>
        <a-tab-pane key="courseSetting" tab="课程设置">
          <a-form-model
            ref="courseSettingForm"
            :model="info"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-form-model-item
              label="试看时长"
              prop="courseSetting.duration"
              help="0代表无限制"
            >
              <a-input
                placeholder="请输入"
                v-model.number="info.courseSetting.duration"
                suffix="秒"
              ></a-input>
            </a-form-model-item>
            <a-form-model-item
              label="课程学时规则"
              prop="courseSetting.hourCountType"
            >
              <a-select
                placeholder="请选择"
                v-model="info.courseSetting.hourCountType"
              >
                <a-select-option
                  :value="info.type"
                  v-for="info in hourCountTypeList"
                  :key="info.type"
                  >{{ info.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              label="每学时时长"
              prop="courseSetting.perHourCountTime"
              v-if="info.courseSetting.hourCountType == 3"
            >
              <a-input
                placeholder="请输入"
                :min="1"
                v-model.number="info.courseSetting.perHourCountTime"
                suffix="分钟"
              ></a-input>
            </a-form-model-item>
            <a-form-model-item
              label="时长偏差值"
              prop="courseSetting.perHourCountDeviationValue"
              v-if="info.courseSetting.hourCountType == 3"
            >
              <a-input
                placeholder="请输入"
                :min="0"
                v-model.number="info.courseSetting.perHourCountDeviationValue"
                suffix="分钟"
              ></a-input>
            </a-form-model-item>
            <a-form-model-item label="课程属性设置">
              <pxkeji-btn
                name="添加属性"
                @click="showCourseGroupInfo()"
                size="small"
                type="primary"
              />
              <a-table
                rowKey="groupId"
                size="small"
                bordered
                :columns="courseTable.columns"
                :pagination="false"
                :dataSource="info.courseSetting.courseGroupList"
                :locale="{ emptyText: '暂无数据' }"
              >
                <template slot="source" slot-scope="source">
                  <span v-if="source == 1">数据字典</span>
                </template>
                <template slot="action" slot-scope="text, record, index">
                  <pxkeji-btn
                    name="修改"
                    @click="showCourseGroupInfo(record)"
                    size="small"
                    type="link"
                  />
                  <a-popconfirm
                    title="是否确认删除此属性？"
                    @confirm="delCourseGroupInfo(index)"
                  >
                    <a-icon slot="icon" type="question-circle-o" />
                    <pxkeji-btn
                      name="删除"
                      size="small"
                      type="link"
                      style="color: red"
                    />
                  </a-popconfirm>
                </template>
              </a-table>
            </a-form-model-item>
            <a-form-model-item
              label="封面图模板"
              help="注：建议图片尺寸270像素X165像素"
            >
              <a-upload
                accept="image/png,image/jpeg"
                listType="picture-card"
                :action="$api.file.uploadFile"
                :fileList="courseTempList"
                :beforeUpload="beforeImgUpload"
                @preview="handleImgPreview"
                @change="handleTempChange"
              >
                <div>
                  <a-icon type="plus" />
                  <div class="ant-upload-text">Upload</div>
                </div>
              </a-upload>
            </a-form-model-item>
            <a-form-model-item :wrapper-col="{ span: 10, offset: 4 }">
              <pxkeji-btn
                name="保存"
                type="primary"
                @click="courseSettingSubmit"
              />
            </a-form-model-item>
          </a-form-model>
        </a-tab-pane>
        <a-tab-pane key="resourceSetting" tab="资源设置">
          <a-form-model
            ref="resourceSettingForm"
            :model="info"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-form-model-item
              label="收费资源试看页数"
              prop="resourceSetting.previewPages"
              help="0代表无限制"
            >
              <a-input
                placeholder="请输入"
                v-model.number="info.resourceSetting.previewPages"
              ></a-input>
            </a-form-model-item>
            <a-form-model-item label="资源属性设置">
              <pxkeji-btn
                name="添加属性"
                @click="showResourceGroupInfo()"
                size="small"
                type="primary"
              />
              <a-table
                rowKey="groupId"
                size="small"
                bordered
                :columns="resourceTable.columns"
                :pagination="false"
                :dataSource="info.resourceSetting.resourceGroupList"
                :locale="{ emptyText: '暂无数据' }"
              >
                <template slot="source" slot-scope="source">
                  <span v-if="source == 1">数据字典</span>
                </template>
                <template slot="action" slot-scope="text, record, index">
                  <pxkeji-btn
                    name="修改"
                    @click="showResourceGroupInfo(record)"
                    size="small"
                    type="link"
                  />
                  <a-popconfirm
                    title="是否确认删除此属性？"
                    @confirm="delResourceGroupInfo(index)"
                  >
                    <a-icon slot="icon" type="question-circle-o" />
                    <pxkeji-btn
                      name="删除"
                      size="small"
                      type="link"
                      style="color: red"
                    />
                  </a-popconfirm>
                </template>
              </a-table>
            </a-form-model-item>
            <a-form-model-item :wrapper-col="{ span: 10, offset: 4 }">
              <pxkeji-btn
                name="保存"
                type="primary"
                @click="resourceSettingSubmit"
              />
            </a-form-model-item>
          </a-form-model>
        </a-tab-pane>
        <a-tab-pane key="memberSetting" tab="账户设置">
          <a-form-model
            ref="memberSettingForm"
            :model="info"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-form-model-item
              label="钱包充值金额"
              prop="memberSetting.rechargeBalanceAmount"
              help="用英文分号分隔多个金额"
            >
              <a-input
                placeholder="请输入"
                v-model="info.memberSetting.rechargeBalanceAmount"
              ></a-input>
            </a-form-model-item>
            <a-form-model-item :wrapper-col="{ span: 10, offset: 4 }">
              <pxkeji-btn
                name="保存"
                type="primary"
                @click="memberSettingSubmit"
              />
            </a-form-model-item>
          </a-form-model>
        </a-tab-pane>
        <a-tab-pane key="orderSetting" tab="订单设置">
          <a-form-model
            ref="orderSettingForm"
            :model="info"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-form-model-item
              label="未支付订单有效期"
              prop="orderSetting.waitingForPayHours"
              help="不设置代表无限制"
            >
              <a-input
                placeholder="请输入"
                v-model.number="info.orderSetting.waitingForPayHours"
                suffix="小时"
              ></a-input>
            </a-form-model-item>
            <a-form-model-item :wrapper-col="{ span: 10, offset: 4 }">
              <pxkeji-btn
                name="保存"
                type="primary"
                @click="orderSettingSubmit"
              />
            </a-form-model-item>
          </a-form-model>
        </a-tab-pane>
      </a-tabs>
    </div>
    <a-modal
      v-model="courseGroup.visible"
      :title="courseGroup.title"
      :footer="false"
      :maskClosable="false"
      destroyOnClose
    >
      <CourseGroupInfo
        :infos="courseGroup.info"
        @callbackMethod="courseGroupBack"
      />
    </a-modal>
    <a-modal
      v-model="resourceGroup.visible"
      :title="resourceGroup.title"
      :footer="false"
      :maskClosable="false"
      destroyOnClose
    >
      <ResourceGroupInfo
        :infos="resourceGroup.info"
        @callbackMethod="resourceGroupBack"
      />
    </a-modal>
    <a-modal
      :visible="previewImgVisible"
      :footer="null"
      @cancel="handleImgCancel"
    >
      <img alt="example" style="width: 100%" :src="previewImgUrl" />
    </a-modal>
  </a-spin>
</template>
<script>
import CourseGroupInfo from "./CourseGroupInfo.vue";
import ResourceGroupInfo from "./ResourceGroupInfo.vue";
let _this;
export default {
  name: "parameter",
  data() {
    let numValid = (rule, val, callback) => {
      if (!/^([1-9][0-9]*)$/.test(val)) {
        callback("请输入数字");
      } else {
        callback();
      }
      callback();
    };
    return {
      isloading: false,
      loadingTip: "加载中...",
      labelCol: { span: 4 },
      wrapperCol: { xxl: 10, xl: 14 },
      info: {
        trainSetting: {
          studentRoleId: null,
          teacherRoleId: null,
          enterpriseRoleId: null,
        },
        courseSetting: {
          duration: 0,
          courseGroupList: [],
        },
        resourceSetting: {
          resourceGroupList: [],
          previewPages: 0,
        },
        memberSetting: {
          rechargeBalanceAmount: "",
        },
        orderSetting: {
          waitingForPayHours: null,
        },
      },
      dictionaries: [],
      courseTempList: [],
      previewImgVisible: false,
      previewImgUrl: "",
      courseTable: {
        columns: [
          {
            dataIndex: "name",
            align: "center",
            title: "属性名称",
          },
          {
            dataIndex: "source",
            align: "center",
            title: "数据源",
            scopedSlots: { customRender: "source" },
          },
          {
            dataIndex: "groupId",
            align: "center",
            title: "数据值",
          },
          {
            dataIndex: "groupName",
            align: "center",
            title: "数据名称",
            customRender: (text, record) => {
              let result = this.dictionaries.filter(
                (item) => item.id == record.groupId
              );
              if (result && result.length > 0) {
                return result[0].name;
              }
            },
          },
          {
            key: "action",
            align: "center",
            title: "操作",
            scopedSlots: { customRender: "action" },
          },
        ],
      },
      resourceTable: {
        columns: [
          {
            dataIndex: "name",
            align: "center",
            title: "属性名称",
          },
          {
            dataIndex: "source",
            align: "center",
            title: "数据源",
            scopedSlots: { customRender: "source" },
          },
          {
            dataIndex: "groupId",
            align: "center",
            title: "数据值",
          },
          {
            dataIndex: "groupName",
            align: "center",
            title: "数据名称",
            customRender: (text, record) => {
              let result = this.dictionaries.filter(
                (item) => item.id == record.groupId
              );
              if (result && result.length > 0) {
                return result[0].name;
              }
            },
          },
          {
            key: "action",
            align: "center",
            title: "操作",
            scopedSlots: { customRender: "action" },
          },
        ],
      },
      courseGroup: {
        visible: false,
        tilte: "",
        info: {},
      },
      resourceGroup: {
        visible: false,
        tilte: "",
        info: {},
      },
      rules: {
        trainSetting: {
          studentRoleId: [
            {
              required: true,
              message: "请选择个人用户角色",
              trigger: "change",
            },
          ],
          teacherRoleId: [
            {
              required: true,
              message: "请选择教师用户角色",
              trigger: "change",
            },
          ],
          enterpriseRoleId: [
            {
              required: true,
              message: "请选择企业用户角色",
              trigger: "change",
            },
          ],
        },
        courseSetting: {
          duration: [
            {
              required: true,
              message: "请输入试看时长",
              trigger: "change",
            },
            { validator: numValid, trigger: "change" },
          ],
          hourCountType: [
            {
              required: true,
              message: "请选择课程学时规则",
              trigger: "change",
            },
          ],
          perHourCountTime: [
            {
              required: true,
              message: "请输入每学时时长",
              trigger: "change",
            },
            { validator: numValid, trigger: "change" },
          ],
          perHourCountDeviationValue: [
            {
              required: true,
              message: "请输入时长偏差值",
              trigger: "change",
            },
            { validator: numValid, trigger: "change" },
          ],
        },
        resourceSetting: {
          previewPages: [
            {
              required: true,
              message: "请输入试看页数",
              trigger: "change",
            },
            { validator: numValid, trigger: "change" },
          ],
        },
        memberSetting: {
          rechargeBalanceAmount: [
            {
              required: true,
              message: "请输入钱包充值金额",
              trigger: "change",
            },
          ],
        },
        orderSetting: {
          waitingForPayHours: [{ validator: numValid, trigger: "change" }],
        },
      },
      roleList: [],
      hourCountTypeList: [],
    };
  },
  components: {
    CourseGroupInfo,
    ResourceGroupInfo,
  },
  mounted() {
    _this = this;
    _this.getRoleForFront();
    _this.getAllDictionary();
    _this.getCourseHourTypes();
    _this.getSystemSetting();
  },
  methods: {
    async getRoleForFront() {
      let res = await _this.$api.role.getRoleForFront();
      if (res.errorCode == _this.$msg.responseCode.success) {
        _this.roleList = res.data;
      }
    },
    async getAllDictionary() {
      let res = await _this.$api.dictionaries.getAll();
      if (res.errorCode == _this.$msg.responseCode.success) {
        _this.dictionaries = res.data;
      }
    },
    getCourseHourTypes() {
      _this.$api.systemSetting.getCourseHourTypes().then((res) => {
        if (res.errorCode == _this.$msg.responseCode.success) {
          _this.hourCountTypeList = res.data;
        }
      });
    },
    getSystemSetting() {
      _this.isloading = true;
      _this.$api.systemSetting
        .getSystemSetting()
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            if (res.data.trainSetting) {
              _this.info.trainSetting = res.data.trainSetting;
            }
            if (res.data.courseSetting) {
              _this.info.courseSetting = res.data.courseSetting;
              if (!_this.info.courseSetting.courseGroupList) {
                _this.info.courseSetting.courseGroupList = [];
              }
              if (_this.info.courseSetting.picTemplate) {
                let picArr = _this.info.courseSetting.picTemplate.split(",");
                picArr.forEach((item, index) => {
                  _this.courseTempList.push({
                    uid: index + 1,
                    name: "pic" + index + ".png",
                    status: "done",
                    url: item,
                  });
                });
              }
            }
            if (res.data.resourceSetting) {
              _this.info.resourceSetting = res.data.resourceSetting;
              if (!_this.info.resourceSetting.resourceGroupList) {
                _this.info.resourceSetting.resourceGroupList = [];
              }
            }
            if (res.data.memberSetting) {
              let amount = res.data.memberSetting.rechargeAmountList || [];
              if (amount.length > 0) {
                _this.info.memberSetting.rechargeBalanceAmount = amount
                  .map((item) => item.recharge)
                  .join(";");
              }
            }
            if (res.data.orderSetting) {
              _this.info.orderSetting = res.data.orderSetting;
            }
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    // 角色设置
    trainSettingSubmit() {
      _this.$refs.trainSettingForm.validate((valid) => {
        if (valid) {
          _this.isloading = true;
          _this.loadingTip = "保存中...";
          _this.$api.systemSetting
            .setTrainSetting(_this.info.trainSetting)
            .then((res) => {
              if (res.errorCode == _this.$msg.responseCode.success) {
                _this.$message.success(res.errorMsg);
              } else {
                _this.$message.error(res.errorMsg);
              }
              _this.isloading = false;
            })
            .catch(() => {
              _this.isloading = false;
            });
        } else {
          return false;
        }
      });
    },
    showCourseGroupInfo(info) {
      _this.courseGroup.title = info ? "编辑属性" : "添加属性";
      _this.courseGroup.info = {
        ...info,
      };
      if (_this.info.courseSetting.courseGroupList) {
        _this.courseGroup.info.selIds =
          _this.info.courseSetting.courseGroupList.map((item) => item.groupId);
      }
      _this.courseGroup.visible = true;
    },
    showResourceGroupInfo(info) {
      _this.resourceGroup.title = info ? "编辑属性" : "添加属性";
      _this.resourceGroup.info = {
        ...info,
      };
      if (_this.info.resourceSetting.resourceGroupList) {
        _this.resourceGroup.info.selIds =
          _this.info.resourceSetting.resourceGroupList.map(
            (item) => item.groupId
          );
      }
      _this.resourceGroup.visible = true;
    },

    delCourseGroupInfo(index) {
      _this.info.courseSetting.courseGroupList.splice(index, 1);
    },

    courseGroupBack(data) {
      let edit = false;
      if (_this.info.courseSetting.courseGroupList.length > 0) {
        let groups = _this.info.courseSetting.courseGroupList;
        for (let i = 0; i < groups.length; i++) {
          if (
            data.groupId == groups[i].groupId ||
            data.name == groups[i].name
          ) {
            groups.splice(i, 1, data);
            edit = true;
            break;
          }
        }
        _this.info.courseSetting.courseGroupList = [...groups];
      }
      if (!edit) {
        _this.info.courseSetting.courseGroupList.push(data);
      }
      _this.courseGroup.visible = false;
    },
    delResourceGroupInfo(index) {
      _this.info.resourceSetting.resourceGroupList.splice(index, 1);
    },
    resourceGroupBack(data) {
      let edit = false;
      if (_this.info.resourceSetting.resourceGroupList.length > 0) {
        let groups = _this.info.resourceSetting.resourceGroupList;
        for (let i = 0; i < groups.length; i++) {
          if (
            data.groupId == groups[i].groupId ||
            data.name == groups[i].name
          ) {
            groups.splice(i, 1, data);
            edit = true;
            break;
          }
        }
        _this.info.resourceSetting.resourceGroupList = [...groups];
      }
      if (!edit) {
        _this.info.resourceSetting.resourceGroupList.push(data);
      }
      _this.resourceGroup.visible = false;
    },
    // 课程设置
    courseSettingSubmit() {
      _this.$refs.courseSettingForm.validate((valid) => {
        if (valid) {
          _this.isloading = true;
          _this.loadingTip = "保存中...";
          if (_this.courseTempList.length > 0) {
            _this.info.courseSetting.picTemplate = _this.courseTempList
              .map((item) => item.url)
              .join();
          } else {
            _this.info.courseSetting.picTemplate = "";
          }
          _this.$api.systemSetting
            .setCourseSetting(_this.info.courseSetting)
            .then((res) => {
              if (res.errorCode == _this.$msg.responseCode.success) {
                _this.$message.success(res.errorMsg);
              } else {
                _this.$message.error(res.errorMsg);
              }
              _this.isloading = false;
            })
            .catch(() => {
              _this.isloading = false;
            });
        } else {
          return false;
        }
      });
    },
    // 资源设置
    resourceSettingSubmit() {
      _this.$refs.resourceSettingForm.validate((valid) => {
        if (valid) {
          _this.isloading = true;
          _this.loadingTip = "保存中...";
          _this.$api.systemSetting
            .setResourceSetting(_this.info.resourceSetting)
            .then((res) => {
              if (res.errorCode == _this.$msg.responseCode.success) {
                _this.$message.success(res.errorMsg);
              } else {
                _this.$message.error(res.errorMsg);
              }
              _this.isloading = false;
            })
            .catch(() => {
              _this.isloading = false;
            });
        } else {
          return false;
        }
      });
    },
    // 账户设置
    memberSettingSubmit() {
      _this.$refs.memberSettingForm.validate((valid) => {
        if (valid) {
          _this.isloading = true;
          _this.loadingTip = "保存中...";
          let amounts =
            _this.info.memberSetting.rechargeBalanceAmount.split(";");
          let rechargeAmountList = [];
          amounts.forEach((item) => {
            rechargeAmountList.push({ recharge: item });
          });
          let formData = {
            rechargeAmountList,
          };
          _this.$api.systemSetting
            .setMemberSetting(formData)
            .then((res) => {
              if (res.errorCode == _this.$msg.responseCode.success) {
                _this.$message.success(res.errorMsg);
              } else {
                _this.$message.error(res.errorMsg);
              }
              _this.isloading = false;
            })
            .catch(() => {
              _this.isloading = false;
            });
        } else {
          return false;
        }
      });
    },
    // 订单设置
    orderSettingSubmit() {
      _this.$refs.orderSettingForm.validate((valid) => {
        if (valid) {
          _this.isloading = true;
          _this.loadingTip = "保存中...";
          _this.$api.systemSetting
            .setOrderSetting(_this.info.orderSetting)
            .then((res) => {
              if (res.errorCode == _this.$msg.responseCode.success) {
                _this.$message.success(res.errorMsg);
              } else {
                _this.$message.error(res.errorMsg);
              }
              _this.isloading = false;
            })
            .catch(() => {
              _this.isloading = false;
            });
        } else {
          return false;
        }
      });
    },
    handleImgCancel() {
      _this.previewImgUrl = "";
      _this.previewImgVisible = false;
    },
    handleImgPreview(file) {
      _this.previewImgUrl = file.url;
      _this.previewImgVisible = true;
    },
    handleTempChange(info) {
      _this.courseTempList = info.fileList;
      if (info.file.status == "done") {
        if (info.file.response.errorCode == "0000") {
          _this.courseTempList[_this.courseTempList.length - 1].url =
            info.file.response.data.path;
        } else {
          _this.$message.error("上传失败");
        }
      } else if (info.file.status == "error") {
        _this.$message.error("上传失败");
      }
    },
    beforeImgUpload(file) {
      let isJPGPNG = false;
      if (file.type === "image/jpeg" || file.type === "image/png") {
        isJPGPNG = true;
      } else {
        isJPGPNG = false;
        _this.$message.error("只能上传jpg/png格式的文件!");
      }
      let isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        _this.$message.error("文件不能超过2MB!");
      }
      return isJPGPNG && isLt2M;
    },
  },
};
</script>

<style>
.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#components-form-demo-advanced-search .ant-form {
  max-width: none;
}

#components-form-demo-advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}
</style>

