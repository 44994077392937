<template>
  <div>
    <a-form-model
      ref="resourceGroupForm"
      :model="info"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="属性名称" prop="name">
        <a-input placeholder="请输入" v-model="info.name"></a-input>
      </a-form-model-item>
      <a-form-model-item label="数据源">
        <a-select v-model="info.source">
          <a-select-option :value="1">数据字典</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="数据值" prop="groupId">
        <a-select v-model="info.groupId" placeholder="请选择" allowClear>
          <a-select-option
            v-for="dic in dictionaries"
            :key="dic.id"
            :value="dic.id"
            :disabled="dic.disabled"
            >{{ dic.name }}</a-select-option
          >
        </a-select>
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 19, offset: 5 }">
        <pxkeji-btn name="保存" type="primary" @click="handleSubmit" />
      </a-form-model-item>
    </a-form-model>
  </div>
</template>
<script>
let _this;
export default {
  props: {
    infos: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
      info: {
        source: 1,
      },
      dictionaries: [],
      rules: {
        name: [{ required: true, message: "请输入属性名称", trigger: "blur" }],
        groupId: [
          { required: true, message: "请选择数据值", trigger: "change" },
        ],
      },
      selectedIds: [],
    };
  },
  mounted() {
    _this = this;
    if (_this.infos.selIds && _this.infos.selIds.length > 0) {
      _this.selectedIds = [..._this.infos.selIds];
    }
    _this.getAllDictionary();
    if (_this.infos.groupId) {
      _this.info = { ..._this.infos };
      delete _this.info.selIds;
    }
  },
  methods: {
    async getAllDictionary() {
      let res = await _this.$api.dictionaries.getAll();
      if (res.errorCode == _this.$msg.responseCode.success) {
        _this.dictionaries = res.data;
        if (_this.selectedIds.length > 0) {
          _this.dictionaries.forEach((item) => {
            if (_this.selectedIds.indexOf(item.id) > -1) {
              item.disabled = true;
            }
          });
        }
      }
    },
    handleSubmit() {
      _this.$refs.resourceGroupForm.validate((valid) => {
        if (valid) {
          _this.$emit("callbackMethod", _this.info);
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style>
</style>